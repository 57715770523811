import { Button, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ClinicianInformation } from "./ClinicianInformation";
import { useDispatch, useSelector } from "react-redux";
import PatientInformation from "../PatientInformation";
import OrderingInformation from "../OrderingInformation";
import DiseaseInformation from "../DiseaseInformation";
import LabInformation from "../LabInformation";
import { PatientSearch } from "./patient-search/PatientSearch";
import { yupResolver } from "@hookform/resolvers/yup";
import { formSchema } from "../../helpers/formSchema";
import { useMuiRegister } from "../../lib/misc";
import { useForm } from "react-hook-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { APP_URL } from "../utils/url";
import { openSnackbar } from "../../store/features/snackbarSlice";
import { useCaseInfo } from "../../hooks/useCaseInfo";
import ReactToPrint from "react-to-print";
import { setError, setErrorMessage, setThisCase } from "../../store/features/caseSlice";
import { formDefaultValue } from "../../helpers/formHelper";
import { setPatientSelected, setPatientsSearched } from "../../store/features/patientSearchSlice";
import api from "../../lib/axios";
import { DevTool } from "@hookform/devtools";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  setFacilitySelected,
  setLabExpanded,
  setNoLabChecked,
  setNotifiable,
  setOrderExpanded,
  setSameAsReporter,
  setTestResultCount,
  setTreatmentCount,
} from "../../store/features/conditionSlice";
import { setSelectedRaces } from "../../store/features/patientSlice";

const formOpts = { mode: "all" };

export const Case = (props) => {
  const { currentUser } = useSelector((state) => state.session);
  const { thisCase, error, errorMessage, caseLoading, dropdownDataLoading } = useSelector((state) => state.case);
  const [submitting, setSubmitting] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const {
    setValue,
    watch,
    control,
    register,
    formState,
    clearErrors,
    reset,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm(formOpts);
  const { noLabChecked, treatmentAllowed } = useSelector((state) => state.condition);

  const readonly = !!params.caseId;
  if (isNaN(params.caseId)) {
    formOpts.resolver = yupResolver(formSchema(noLabChecked, treatmentAllowed, currentUser));
  }
  const caseInfo = useCaseInfo(params.caseId, reset);

  const formProps = {
    ...props,
    caseId: params.caseId,
    readonly,
    clearErrors,
    setValue,
    getValues,
    errors,
    reset,
    trigger,
    register: useMuiRegister(register),
    control,
    watch,
    currentUser,
    initialized,
  };

  useEffect(() => {
    if (!params.caseId) {
      reset(formDefaultValue);
      setInitialized(true);
      dispatch(setError(false));
      dispatch(setSelectedRaces([]));
      dispatch(setPatientsSearched(false));
      dispatch(setPatientSelected(false));
      dispatch(setNoLabChecked(false));
      dispatch(setOrderExpanded(true));
      dispatch(setLabExpanded(true));
      dispatch(setNotifiable(false));
      dispatch(setTestResultCount(1));
      dispatch(setTreatmentCount(1));
      dispatch(setSameAsReporter(false));
      dispatch(setFacilitySelected(false));
    } else {
      setInitialized(false);
    }
  }, [params.caseId]);

  const submit = (e) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    console.log("Submitting", e);
    // Delete unwanted fields from the form schema
    delete e["patient_search"];
    delete e["order"]["facility"]["phone"];
    delete e["order"]["facility"]["id"];
    delete e["event"]["treatment_id"];
    delete e["event"]["treatment_status"];
    delete e["treatmentAllowed"];
    delete e["event"]["treatmentStatus"];
    delete e["noLabReported"];
    delete e["patient"]["phone"];

    dispatch(setPatientsSearched(false));
    api
      .post("/notifications/", { notification: e })
      .then((response) => {
        if (response.data.status === "ok") {
          // show snack bar
          dispatch(setThisCase(response.data.notification));
          console.log(response.data.notification);
          navigate(APP_URL.VIEW_CASE(response.data.notification?.id, true));
        }
        setSubmitting(false);
        dispatch(openSnackbar({ message: response.data.message, severity: "success" }));
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(openSnackbar({ message: "Problem with creating a case", severity: "error" }));
        reset(formDefaultValue);
        setSelectedRaces([]);
        console.log(error);
      });
  };

  const onError = (e) => {
    console.debug(e);
  };

  return (
    <>
      {caseLoading || dropdownDataLoading ? (
        <Box sx={{ display: "flex", minHeight: "400px", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box>
      ) : !error ? (
        <>
          {!!params.caseId && (
            <div className="flex justify-between align-center">
              <ReactToPrint
                trigger={() => (
                  <Button color="secondary" variant="contained">
                    Print Report
                  </Button>
                )}
                content={() => props.printRef.current}
              />
              <div>
                <p className="m-0">
                  <b>Submitter: </b>
                  {thisCase?.user?.last_name}, {thisCase?.user?.first_name} -
                  <a className="pl-1" href={`mailto:${thisCase?.user?.username}`}>
                    {thisCase?.user?.username}
                  </a>
                </p>
              </div>
            </div>
          )}
          {window.location.href.indexOf("submitted") !== -1 && (
            <div className="text-center">
              <div> NOTIFICATION SUBMITTED </div>
              <div> Please print for your records</div>
            </div>
          )}
          <form className="mt-3 formContainer" onSubmit={handleSubmit(submit, onError)}>
            <div className="m-3">
              <FormControl fullWidth>
                <ClinicianInformation {...formProps} />
                {!params.caseId && <PatientSearch {...formProps} />}
                <PatientInformation {...formProps} />
                <DiseaseInformation {...formProps} />
                <OrderingInformation {...formProps} />
                <LabInformation {...formProps} />
                <div className="pt-3">
                  <TextField
                    fullWidth
                    multiline
                    name="notes"
                    inputProps={{ readOnly: readonly }}
                    InputLabelProps={{ shrink: watch("notes") !== "" }}
                    label="Notes"
                    error={!!errors?.notes}
                    helperText={errors?.notes && errors?.notes?.message}
                    {...register("notes")}
                  />
                </div>
              </FormControl>
            </div>
            {!params.caseId && (
              <Card className="mt-3" elevation={0} variant="outlined">
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={() => reset()}>
                        Reset
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="secondary" data-testid="save" type="submit">
                        Submit
                      </Button>
                      {/* <Button
                        variant="contained"
                        color="secondary"
                        data-testid="save"
                        onClick={async () => {
                          await trigger();
                          console.log(formState);
                          console.log(formState.errors);
                          console.log(formState.isValid);
                          console.log(getValues());
                        }}
                      >
                        Check
                      </Button> */}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </form>
          {/* <DevTool control={control} /> */}
        </>
      ) : (
        <Card className="mt-3" elevation={0} variant="outlined">
          <CardContent>
            <p className="font-bold text-center">{errorMessage}</p>
          </CardContent>
        </Card>
      )}
    </>
  );
};
